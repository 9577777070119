.body {
  @apply text-base leading-7 max-w-6xl mx-auto;
}

.heading {
  @apply text-5xl pt-1 pb-2 font-semibold tracking-wide cursor-pointer mb-2;
}

.pageHeading {
  @apply pt-1 pb-4 text-2xl leading-7 font-bold tracking-wide;
}

.sectionHeading {
  @apply pt-1 pb-2 text-2xl font-bold tracking-wide cursor-pointer mb-2;
}
